/* eslint-disable */
import React, { useState } from 'react'

import { Button, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';

import { Footer, Header, Loader } from '..'
import { Form2Style, FormStyle } from "../Styles/index";
import { inputBillProgram } from '../../api/ApiUtils';
import PopupModal from '../ErrorPage/PopupModal';
import { useNavigate } from 'react-router-dom';

const InputBills = () => {
    const navigate = useNavigate();
    const { formContainer, formRegistrationTitle } = Form2Style();
    const { primaryButton, formFieldName, formInputFieldContainer, formInputField, formSelectField } = FormStyle();

    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState('');
    const [program, setProgram] = useState('');
    const [monthlyWage, setMonthlyWage] = useState("");
    const [monthlyWageRaw, setMonthlyWageRaw] = useState("");

    const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
    const [error, setError] = useState(false);

    const handlemonthlyWage = (value) => {

        let dInput = value;
        let output = "";
        try {
            dInput = dInput.replace(/[^0-9]/g, ""); // remove all chars including spaces, except digits.
            let totalSize = dInput.length;
            for (let i = totalSize - 1; i > -1; i--) {
                output = dInput.charAt(i) + output;
                let cnt = totalSize - i;
                if (cnt % 3 === 0 && i !== 0) {
                    output = "." + output; // seperator is "."
                }
            }
        } catch (err) {
            output = dInput; // it won't happen, but it's sweet to catch exceptions.
        }
        setMonthlyWage(output)
        // removing decimals.........
        // console.log((output.replace(/[^0-9|-]/g, "")));
        setMonthlyWageRaw(output.replace(/[^0-9|-]/g, ""))
    };

    const handleSubmit = async () => {

        if (month && monthlyWageRaw && program) {
            setLoading(true);
            setError(false);

            let [JHT, JKK, JKM] = [program.includes("JHT") ? 'Y' : 'N', program.includes("JKK") ? 'Y' : 'N', program.includes("JKM") ? 'Y' : 'N'];

            const body = {
                "upah": monthlyWageRaw,
                "program": `JHT=${JHT}:${month}#JKK=${JKK}:${month}#JKM=${JKM}:${month}`,
                "lokasiPekerjaan": JSON.parse(localStorage.getItem("cityCode")),
                "nik": JSON.parse(localStorage.getItem("nik")),
                "enrollmentId": JSON.parse(localStorage.getItem("enrollmentId"))
            }
            const response = await inputBillProgram(body);

            if (response) navigate("/redirect");
            else showErrorPopUp({ showPopUp: true });
            setLoading(false);
        }
        else setError(true)

        // logic for creating "program" in boby object to send in API. => https://ayopop.atlassian.net/wiki/spaces/IN/pages/1864597565/Pre-Integration+Document+BPJS+TK+-+Registration+Payment+BPU#4)-Input-Program-Data
    }

    const handlePopUpClose = () => {
        showErrorPopUp({ showPopUp: false });
    }

    return (
        <div id="InputBills">
            <PopupModal open={errorPopUp.showPopUp} message={"Gagal hitung detail"} error={true} handlePopUpClose={handlePopUpClose} />
            <Loader loading={loading} />

            <Header text={"Registrasi Iuran BPU"} />
            <div className={formContainer}>
                <img src="images/bpjs-ketenagakerjaan.png" alt="logo" height="60" />

                <Typography className={formRegistrationTitle}>
                    Formulir Registrasi
                </Typography>
                <Typography style={{ fontSize: "14px", color: "#666" }}>
                    Isi data berikut untuk memulai registrasi BPJS Bukan Penerima Upah
                </Typography>


                <div style={{ textAlign: "left", marginTop: "16px" }}>
                    <Typography id={"upah-bulanan-label"} className={formFieldName}>Upah Bulanan</Typography>
                    <TextField id={"upah-bulanan-value"} variant={"standard"} placeholder="Upah Bulanan" className={formInputFieldContainer} inputProps={{ className: formInputField }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }} autoComplete={"off"}
                        onChange={e => {
                            handlemonthlyWage(e.target.value)
                        }}
                        value={monthlyWage}
                        error={error && monthlyWage == ""}
                        helperText={error && monthlyWage == "" ? "Mohon isi Ubah Bulanan dengan benar" : ""}
                    />

                    <Typography id={"pilih-program-label"} className={formFieldName}>Pilih Program</Typography>
                    <RadioGroup onChange={e => setProgram(e.target.value)}>
                        <FormControlLabel id={"2-program"} control={<Radio />} label="2 Program (JKK, JKM)" value="[JKK, JKM]" />
                        <FormControlLabel id={"3-program"} control={<Radio />} label="3 Program (JKK, JKM, JHT)" value="[JKK, JKM, JHT]" />
                    </RadioGroup>
                    <span style={{ display: error && program == "" ? "" : "none", color: "#d32f2f", fontSize: "12px", fontWeight: "500" }} >Mohon pilih program dengan benar</span>

                    <Typography id={"pilih-bulan-label"} className={formFieldName} style={{ marginTop: "16px" }}>Pilih Bulan</Typography>
                    <Select /*error={error && month==""}*/ id={"pilih-bulan-value"} variant={"standard"} className={`${formInputFieldContainer} ${formSelectField}`}
                        value={month} onChange={(e) => setMonth(e.target.value)}
                    >
                        <MenuItem id={"1-bulan"} value={1}>1 Bulan</MenuItem>
                        <MenuItem id={"2-bulan"} value={2}>2 Bulan</MenuItem>
                        <MenuItem id={"3-bulan"} value={3}>3 Bulan</MenuItem>
                        <MenuItem id={"4-bulan"} value={6}>6 Bulan</MenuItem>
                        <MenuItem id={"5-bulan"} value={12}>12 Bulan</MenuItem>
                    </Select>
                    <span style={{ display: error && month == "" ? "" : "none", color: "#d32f2f", fontSize: "12px", fontWeight: "500" }} >Mohon pilih bulan dengan benar</span>

                </div>

            </div>

            <div style={{ padding: "16px" }}>
                <Button id={"submit"}
                    className={primaryButton}
                    onClick={e => handleSubmit()}
                >
                    SUBMIT
                </Button>
            </div>

            <Footer />
        </div>
    )
}

export default InputBills;