/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { IconButton, InputAdornment, Paper, Slide, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const SelectBranchDropDown = ({ list, slideOpen, setOpenBranchDropDown, onChangeKantorCabangBPJS, title }) => {

    const [name, setName] = useState("");
    const [branchArray, setBranch] = useState([]);

    useEffect(() => {
        if (name != "") {
            let sortedBranchList = [];
            for (let i = 0; i < list.length; i++) {
                let isBranchName = list[i].nama.toLowerCase().includes(name.toLocaleLowerCase());
                if (isBranchName) {
                    sortedBranchList.push(list[i])
                }
            }
            setBranch([...sortedBranchList]);
        }
        if (name == "") setBranch([...list])
    }, [name]);

    // store all station names namesArray.
    useEffect(() => {
        setBranch([...list]);
    }, [list]);

    return (
        <div style={{ position: 'fixed', top: "0", bottom: "0", right: "0", left: "0", background: "#80808078", display: slideOpen ? "" : "none" }} >
            <div style={{ position: "absolute", top: "100px", bottom: "0", right: "0", left: "0", margin: "auto", maxWidth: "640px", borderRadius: "16px 16px 0 0", background: "#FFF", display: slideOpen ? "" : "none" }}>
                <Slide direction={"up"} in={slideOpen} className={"sliderBranchList"} id='slider-container' >
                    <Paper id="slider-paper" style={{ height: slideOpen ? "100%" : "0%" }} >
                        <div style={{ padding: "16px", paddingTop: "0", height: "100%" }} >

                            <div style={{ margin: "auto", width: "48px", height: "4px", background: "#DDD", borderRadius: "8px" }} />

                            <div className={"selectBranchSliderHeader"}>
                                <Typography children={title} className='popUpHeaderText' id="headerText" />
                                <IconButton onClick={() => { setName(""); setOpenBranchDropDown(false); }}> <CloseIcon style={{ color: "#1A1A1A" }} /> </IconButton>
                            </div>

                            <TextField fullWidth placeholder='Cari Kantor Cabang'
                                onChange={(e) => setName(e.target.value)} autoComplete={"off"}
                                value={name}
                                autoFocus
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize='medium' style={{ color: "#ADA6AE" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <div className={"branchListContainer"}>
                                {
                                    branchArray.map((item, i) => {
                                        let name = item.nama.split("#");
                                        return (
                                            <Typography key={i} onClick={() => {
                                                onChangeKantorCabangBPJS(item);
                                                setOpenBranchDropDown(false);
                                                console.log("code--", item)
                                            }}
                                                id={`kantor-cabang-bpjs-value-${item.kode}`}
                                                style={{
                                                    whiteSpace: 'break-spaces', maxWidth: '600px',
                                                    borderBottom: "1px solid #ddd",
                                                    padding: "5px"
                                                }}>
                                                {name[0]}
                                            </Typography>)
                                    })
                                }
                            </div>

                        </div>
                    </Paper>
                </Slide>
            </div>
        </div>
    )
}

export default SelectBranchDropDown;