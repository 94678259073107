/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { Header, Footer } from "../index";
import { Typography, LinearProgress } from "@mui/material";
import { Form2Style } from "../Styles/index";
import { calculateBills } from '../../api/ApiUtils';
import PopupModal from '../ErrorPage/PopupModal';
import { useNavigate } from 'react-router-dom';

const Redirect = () => {
    const [state, setState] = useState({});
    const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
    const nik = JSON.parse(localStorage.getItem("nik"));
    const navigate = useNavigate();
    useEffect(() => {
        const temp = async () => {
            const response = await calculateBills();
            if (response.success) {
                navigate("/success");
            } else {
                showErrorPopUp({ showPopUp: true });
                setState({ ...state, errorMessage: 'Something went wrong! Please try again.' })
            }
        }
        temp();
    }, []);
    const { formRegistrationTitle } = Form2Style();

    const handlePopUpClose = () => {
        showErrorPopUp({ showPopUp: false });
        navigate(`/registration/failed?nik=${nik}`);
    }

    return (
        <div style={{ background: "#fff", height: "100vh" }}>
            <Header text={"Registrasi luran BPU"} cancel={false} />
            <PopupModal open={errorPopUp.showPopUp} message={state.errorMessage} error={true} handlePopUpClose={handlePopUpClose} />
            <div style={{ textAlign: "center", padding: "16px" }}>
                <img src='/images/bpjs-ketenagakerjaan.png' height={80} alt="logo" />

                <Typography className={formRegistrationTitle} >Formulir Registrasi</Typography>
                <Typography style={{ fontSize: "14px", color: "#666", marginTop: "24px", marginBottom: "24px" }}>
                    Pendaftaran Anda Telah berhasil disubmit, mohon menunggu kami sedang menghitung jumlah tagihan anda
                </Typography>

                <LinearProgress style={{ height: "12px", borderRadius: "2px" }} />
                <Typography children={"Mohon menunggu..."} style={{ fontStyle: "italic", marginTop: "8px", color: "#666" }} />
            </div>
            <Footer />
        </div>
    )
}

export default Redirect