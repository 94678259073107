/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";

import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import Clock from "./Clock";
import { getBranchList, getJobList, getProvinceList } from "../../api/ApiUtils";
import { Loader } from "..";

const VerifiedForm2 = ({
  verifyOpen,
  FormStyle,
  state,
  onChangeEmailHandler,
  onChangeAlamatPadaKTP,
  onChangeKantorCabangBPJS,
  onChangeJenisPekerjaan,
  onChangeLokasiPekerjaanProvinsi,
  onChangeLokasiPekerjaanKota,
  onChangeJamMulaiBekerja,
  onChangeJamSelesaiBekerja,
  onBlurKantorCabangBPJS,
  onBLurJenisPekerjaan,
  onBlurLokasiPekerjaanProvinsi,
  onBlurLokasiPekerjaanKota,
  handleOnOpen,
  handleOnClose,
  setOpenBranchDropDown,
  setData,
  data
}) => {
  const {
    formFieldName,
    formInputFieldContainer,
    formInputField,
    formSelectField,
    fieldErrorLabel,
  } = FormStyle();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const temp = async () => {
      setLoading(true)
      let branchList = await getBranchList();
      let jobList = await getJobList();
      let provinceList = await getProvinceList();
      setLoading(false)
      setData({
        ...data,
        branchList: branchList,
        jobList: jobList,
        provinceList: provinceList
      });
    }
    if (verifyOpen) {
      temp();
    }

  }, [verifyOpen])

  if (!verifyOpen) return null;
  return (
    <Fragment>
      <Loader loading={loading} />
      <Typography id={"alamat-email-label"} className={formFieldName}>Alamat Email</Typography>
      <TextField id={"alamat-email-value"}
        onChange={onChangeEmailHandler}
        error={state.fieldErrors.email ? true : false}
        helperText={state.fieldErrors.email}
        variant={"standard"}
        placeholder="Alamat Email"
        className={formInputFieldContainer}
        inputProps={{ className: formInputField }}
      />

      <Typography id={"alamat-pada-ktp-label"} className={formFieldName}>Alamat pada KTP</Typography>
      <TextField id={"alamat-pada-ktp-value"}
        onChange={onChangeAlamatPadaKTP}
        error={state.fieldErrors.alamatPadaKTP ? true : false}
        helperText={state.fieldErrors.alamatPadaKTP}
        variant={"standard"}
        placeholder="Alamat pada KTP"
        className={formInputFieldContainer}
        inputProps={{ className: formInputField }}
      />

      <FormControl
        error={state.fieldErrors.kantorCabangBPJS ? true : false}
        className={`${formInputFieldContainer}`}
      >
        <Typography id={"kantor-cabang-bpjs-label"} className={formFieldName}>Kantor Cabang BPJS</Typography>
        {/* <Select id={"kantor-cabang-bpjs-value"}
          variant={"standard"}
          className={`${formSelectField}`}
          value={state.data.kantorCabangBPJS}
          onChange={onChangeKantorCabangBPJS}
          onBlur={onBlurKantorCabangBPJS}
          onOpen={handleOnOpen}
          error={state.fieldErrors.kantorCabangBPJS ? true : false}
        >
          {data.branchList.map((item, i)=>{
            let name = item.nama.split("#");
            // name = state.openBranch ? item.nama : name[0];
            return (<MenuItem key={i} onClick={handleOnClose} id={`kantor-cabang-bpjs-value-${item.kode}`} style={{whiteSpace: 'break-spaces', maxWidth: '600px',
              borderBottom: "1px solid #ddd"}} value={item.kode}>{name[0]}</MenuItem>)
          })}
        </Select> */}
        <TextField variant={"standard"}
          className={`${formSelectField}`}
          onBlur={onBlurKantorCabangBPJS}
          value={state.data.kantorCabangBPJS.nama?.split('#')[0]}
          error={state.fieldErrors.kantorCabangBPJS ? true : false}
          onClick={() => setOpenBranchDropDown(true)} />
        <FormHelperText className={fieldErrorLabel}>
          {state.fieldErrors.kantorCabangBPJS}
        </FormHelperText>
      </FormControl>

      <FormControl
        error={state.fieldErrors.jenisPekerjaan ? true : false}
        className={`${formInputFieldContainer}`}
      >
        <Typography id={"jenis-pekerjaan-label"} className={formFieldName}>Jenis Pekerjaan</Typography>
        <Select id={"jenis-pekerjaan-value"}
          variant={"standard"}
          className={`${formSelectField}`}
          value={state.data.jenisPekerjaan}
          onChange={onChangeJenisPekerjaan}
          onBlur={onBLurJenisPekerjaan}
          error={state.fieldErrors.jenisPekerjaan ? true : false}
        >
          {data.jobList.map((item, i) => {
            return (<MenuItem key={i} id={`jenis-pekerjaan-value-${item.kd_pekerjaan}`} style={{
              whiteSpace: 'break-spaces',
              borderBottom: "1px solid #ddd"
            }} value={item.kd_pekerjaan}>{item.nama_pekerjaan}</MenuItem>)
          })}
        </Select>
        <FormHelperText className={fieldErrorLabel}>
          {state.fieldErrors.jenisPekerjaan}
        </FormHelperText>
      </FormControl>

      <FormControl
        error={state.fieldErrors.lokasiPekerjaanProvinsi ? true : false}
        className={`${formInputFieldContainer}`}
      >
        <Typography id={"lokasi-pekerjaan-provinsi-label"} className={formFieldName}>
          Lokasi Pekerjaan (Provinsi)
        </Typography>
        <Select id={"lokasi-pekerjaan-provinsi-value"}
          variant={"standard"}
          className={`${formSelectField}`}
          value={state.data.lokasiPekerjaanProvinsi}
          onChange={onChangeLokasiPekerjaanProvinsi}
          onBlur={onBlurLokasiPekerjaanProvinsi}
          error={state.fieldErrors.lokasiPekerjaanProvinsi ? true : false}
        >
          {data.provinceList.map((item, i) => {
            return (<MenuItem key={i} id={`lokasi-pekerjaan-provinsi-${item.kode}`} style={{
              whiteSpace: 'break-spaces',
              borderBottom: "1px solid #ddd"
            }} value={item.kode}>{item.nama}</MenuItem>)
          })}
        </Select>
        <FormHelperText className={fieldErrorLabel}>
          {state.fieldErrors.lokasiPekerjaanProvinsi}
        </FormHelperText>
      </FormControl>

      <FormControl
        error={state.fieldErrors.lokasiPekerjaanKota ? true : false}
        className={`${formInputFieldContainer}`}
      >
        <Typography id={"lokasi-pekerjaan-kota-label"} className={formFieldName}>
          Lokasi Pekerjaan (Kota)
        </Typography>
        <Select id={"lokasi-pekerjaan-kota-value"}
          variant={"standard"}
          className={`${formSelectField}`}
          onChange={onChangeLokasiPekerjaanKota}
          onBlur={onBlurLokasiPekerjaanKota}
          value={state.data.lokasiPekerjaanKota}
        >
          {state.data.cityList.map((item, i) => {
            return (<MenuItem key={i} id={`lokasi-pekerjaan-kota-${item.kode}`} style={{
              whiteSpace: 'break-spaces',
              borderBottom: "1px solid #ddd"
            }} value={item.kode}>{item.nama}</MenuItem>)
          })}
        </Select>
        <FormHelperText className={fieldErrorLabel}>
          {state.fieldErrors.lokasiPekerjaanKota}
        </FormHelperText>
      </FormControl>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: "1", marginRight: "8px" }}>
          <Typography id={"jam-mulai-bekerja-label"} className={formFieldName}>Jam Mulai Bekerja</Typography>
          <div>
            <Clock
              id={"jam-mulai-bekerja-value"}
              defaultTime={state.data.jamMulaiBekerja}
              setTime={onChangeJamMulaiBekerja}
              FormStyle={FormStyle}
            />
          </div>
        </div>

        <div style={{ flex: "1", marginLeft: "8px" }}>
          <Typography id={"jam-selesai-bekerja-label"} className={formFieldName}>Jam Selesai Bekerja</Typography>
          <div>
            <Clock
              id={"jam-selesai-bekerja-value"}
              defaultTime={state.data.jamSelesaiBekerja}
              setTime={onChangeJamSelesaiBekerja}
              FormStyle={FormStyle}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VerifiedForm2;
