import { makeStyles } from "@mui/styles";

const FormStyle = makeStyles({
  formFieldName: {
    fontWeight: "bold !important",
    fontSize: "12px !important",
    color: "#999",
  },
  formInputFieldContainer: {
    width: "100%",
    margin: "8px  0 16px !important",

    "& .css-1480iag-MuiInputBase-root-MuiInput-root:before": {
      borderBottom: "1px solid #DDD !important",
    },
    "& .css-1480iag-MuiInputBase-root-MuiInput-root:after": {
      borderBottom: "0",
    },
  },
  formInputField: {
    fontSize: "16px !important",
    color: "#333",
  },
  formSelectField: {
    "& .MuiSelect-select": {
      fontSize: "16px !important",
      background: "white !important",
      color: "#333",
    },
    "& .MuiInput-input": {
      cursor: "pointer !important"
    },
    "& .css-a88p61-MuiInputBase-root-MuiInput-root:before": {
      borderBottom: "1px solid #DDD !important",
    },
    "& .css-a88p61-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before":
    {
      borderBottom: "1px solid #DDD !important",
    },
  },
  fieldErrorLabel: {
    marginTop: "0px !important",
    marginLeft: "0px !important",
  },

  primaryButton: {
    color: "white !important",
    background: "#4A90E2 !important",
    textAlign: "center !important",
    fontWeight: "bold !important",
    fontSize: "14px !important",
    width: "100% !important",
  },
  disabledButton: {
    color: "white !important",
    textAlign: "center !important",
    fontWeight: "bold !important",
    fontSize: "14px !important",
    width: "100% !important",
    background: "#808080 !important",
  },
  secondaryButton: {
    color: "#4A90E2 !important",
    textAlign: "center",
    fontWeight: "bold !important",
    fontSize: "14px !important",
    width: "100%",
    border: "1px solid #DDD !important",
    marginTop: "8px !important",
  },

  sliderContainer: {
    position: "fixed",
    right: "0",
    left: "0",
    bottom: "0",
    borderRadius: "16px 16px 0 0 !important",
    margin: "auto",
    maxWidth: "640px"
  },
  backdrop: {
    height: "100vh",
    width: "100vw",
    position: "absolute",
    background: "#00000040",
    maxWidth: "640px"
  },

  selectDateOfBirth: {
    '& .MuiPickersToolbar-penIconButton': {
      display: "none",
    },
    '& .MuiDialogActions-root>:first-of-type': {
      display: 'none'
    },
    // giv a line after the date displayed at top in Date Picker
    '& .PrivatePickersToolbar-root': {
      borderBottom: "1px solid #DDD !important"
    },
    // removed the edit button which changed the view of the date picker
    '& .PrivateDatePickerToolbar-penIcon': {
      display: "none",
    },
    // change color and background of the CANCEL and OK button
    '& .MuiButton-root': {
      background: "#004C97",
      color: "#FFF",
      '&:hover': {
        background: "#00478e",
      }
    },
    // background color applied to date when selected and also when seledted and hover on it
    '& .Mui-selected': {
      // backgroundColor: "#1A1A1A !important",
      // '&:hover': {
      //     backgroundColor: "#1a1a1abf !important"
      // }
    }
  }
});

export default FormStyle;
