import moment from "moment";

/* eslint-disable */
export const validationMessages = {
  nik: "Masukkan NIK yang benar",
  nama_pada_ktp: "Masukkan Nama yang benar",
  Tanggal_Lahir_pada_KTP: "Umur tidak sesuai ketentuan",
  Nomor_HP: "Nomor HP tidak sesuai",
  Alamat_Email: "Email tidak terdaftar",
  Alamat_pada_KTP: "Isi alamat dengan benar",
  Kantor_Cabang_BPJS: "Kantor cabang harus diisi",
  Jenis_Pekerjaan: "Jenis Pekerjaan harus diisi",
  Lokasi_Pekerjaan_Provinsi: "Lokasi Pekerjaan harus diisi",
  Lokasi_Pekerjaan_Kota: "Lokasi Pekerjaan harus diisi",
  Jam_Mulai_Bekerja: "Jam mulai bekerja harus diisi",
  Jam_Selesai_Bekerja: "Jam selesai bekerja harus diisi",
};
const re =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const re_namaKtp = /^([a-zA-Z0-9 _-]+)$/;

export const validateNik = (value) => {
  if (value.length < 16 || value.length > 20) {
    return validationMessages.nik;
  }
};

export const validateKtp = (value) => {
  if (value.length < 1 || value.length > 30 || !re_namaKtp.test(value)) {
    return validationMessages.nama_pada_ktp;
  }
};

export const validateTanggalLahirpadaKTP = (value) => {
  if (!value) {
    return validationMessages.Tanggal_Lahir_pada_KTP;
  }
  else { // if we have value check if it is less maxAge and more than minAge
    let minAge = moment().subtract(12, 'years').format("YYYYMMDD");
    let maxAge = moment().subtract(65, 'years').format("YYYYMMDD");
    let date = moment(value).format("YYYYMMDD");

    if (date >= minAge || date <= maxAge)
      return "Umur tidak sesuai ketentuan";
  }
};

export const validateNomorHP = (value) => {
  if (value.substring(0, 1) != 0) {
    return "First digit should be 0";
  }
  if (!(value.length >= 8 && value.length <= 12)) {
    return validationMessages.Nomor_HP;
  }
};

export const validateAlamatEmail = (value) => {
  if (!re.test(value)) {
    return validationMessages.Alamat_Email;
  }
};

export const validateAlamatPadaKTP = (value) => {
  if (value.length < 3) {
    return validationMessages.Alamat_pada_KTP;
  }
};

export const validateKantorCabangBPJS = (value) => {
  if (!value) {
    return validationMessages.Kantor_Cabang_BPJS;
  }
};

export const validateJenisPekerjaan = (value) => {
  if (!value) {
    return validationMessages.Jenis_Pekerjaan;
  }
};

export const validateLokasiPekerjaanProvinsi = (value) => {
  if (!value) {
    return validationMessages.Lokasi_Pekerjaan_Provinsi;
  }
};

export const validateLokasiPekerjaanKota = (value) => {
  if (!value) {
    return validationMessages.Lokasi_Pekerjaan_Kota;
  }
};

export const validateJamMulaiBekerja = (value) => {
  if (!value) {
    return validationMessages.Jam_Mulai_Bekerja;
  }
};

export const validateJamSelesaiBekerja = (value) => {
  if (!value) {
    return validationMessages.Jam_Selesai_Bekerja;
  }
};
