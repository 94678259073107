import axios from "axios";

const enrollmentBaseUrl = process.env.REACT_APP_BASEURL;
const clientId = process.env.REACT_APP_CLIENTID;

const enrollmentBptBaseUrl = enrollmentBaseUrl + "/bpt";
const headers = {
  headers: {
    "Content-Type": "application/json",
    "Clientid": clientId
  },
};


export const ValidateEnrollmentId = async () => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    let requestBody = {
      enrollmentId: enrollmentId,
    };

    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBaseUrl + "/validation";
    const response = await axios.post(url, requestBody, headers);
    return response.data;
  } catch (error) {
    return { success: false };
  }
};

export const checkUser = async (nik) => {
  let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
  try {
    let requestBody = {
      nik: nik,
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBptBaseUrl + "/user/check";
    const response = await axios.post(url, requestBody, headers);
    return response.data;
  } catch (error) {
    return { success: false, responseCode: 500 };
  }
};

export const verifyUser = async (payload) => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    let requestBody = {
      ...payload,
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBptBaseUrl + "/user/verify";
    const response = await axios.post(url, requestBody, headers);
    return response.data;
  } catch (error) {
    return { success: false, responseCode: 500 };
  }
};

export const getBranchList = async () => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    let nik = JSON.parse(localStorage.getItem("nik"));
    let requestBody = {
      level: "3",
      nik: nik,
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBptBaseUrl + "/branch/office/list";
    const response = await axios.post(url, requestBody, headers);
    if (response.data.success) {
      return response.data.branchOfficeList;
    }
    return [];
  } catch (error) {
    return [];
  }
};


export const getJobList = async () => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    let nik = JSON.parse(localStorage.getItem("nik"));

    let requestBody = {
      nik: nik,
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBptBaseUrl + "/job/list";
    const response = await axios.post(url, requestBody, headers);
    if (response.data.success) {
      return response.data.jobList;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getProvinceList = async () => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    let nik = JSON.parse(localStorage.getItem("nik"));

    let requestBody = {
      nik: nik,
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBptBaseUrl + "/province/list";
    const response = await axios.post(url, requestBody, headers);
    if (response.data.success) {
      return response.data.provinceList;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getCityList = async (provinceValue) => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    let nik = JSON.parse(localStorage.getItem("nik"));

    let requestBody = {
      kodePropinsi: provinceValue,
      nik: nik,
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBptBaseUrl + "/city/list";
    const response = await axios.post(url, requestBody, headers);
    if (response.data.success) {
      return response.data.cityList;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const inputBillProgram = async (body) => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    let nik = JSON.parse(localStorage.getItem("nik"));

    body.nik = nik;
    body.enrollmentId = enrollmentId

    const URL = enrollmentBptBaseUrl + "/input/program/data";

    const response = await axios.post(URL, body, headers);
    if (response.data.success) {
      localStorage.setItem("dataIuran", JSON.stringify(response.data.data.kode))
      return true
    }
    else return false
  } catch (error) {
    console.log("error ==> ", error)
    return false
  }
}

export const cancelEnrollment = async () => {
  try {
    const URL = enrollmentBptBaseUrl + "/cancel";
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    const body = { enrollmentId }
    const res = await axios.post(URL, body, headers)
    if (res.data.success) {
      return true;
    }
  } catch (error) {
    console.log(error);
  };
  return false
}

export const registerUser = async (payload) => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

    let requestBody = {
      ...payload,
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBptBaseUrl + "/user/register";
    const response = await axios.post(url, requestBody, headers);
    return response.data.success;
  } catch (error) {
    return false;
  }
};

export const calculateBills = async () => {
  try {
    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
    let nik = JSON.parse(localStorage.getItem("nik"));
    let dataIuran = JSON.parse(localStorage.getItem("dataIuran"));

    let requestBody = {
      nik: nik,
      dataIuran: dataIuran,
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = enrollmentBptBaseUrl + "/calculate/bills";
    const response = await axios.post(url, requestBody, headers);
    if (response.data.success) {
      localStorage.setItem("kodeIuran", JSON.stringify(response.data.data.kodeIuran));
    }
    return response.data;
  } catch (error) {
    return { success: false, responseCode: 500 };
  }
};