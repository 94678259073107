import { makeStyles } from "@mui/styles";

const Form2Style = makeStyles({
    formContainer: {
        padding: "16px",
        background: "white",
        marginTop: "8px",
        textAlign: "center",
        borderTop: "2px solid green"
    },

    formRegistrationTitle: {
        fontWeight: "bold !important",
        fontSize: "20px !important",
        marginTop: "32px !important",
        marginBottom: "8px !important",
        color: "#333"
    }

})

export default Form2Style;