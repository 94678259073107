/* eslint-disable */
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Header, Loader } from "..";
import { Form2Style, FormStyle } from "../Styles";
import { useNavigate } from "react-router-dom";
import PopupModal from "../ErrorPage/PopupModal";

const SuccessPage = () => {

  const { formContainer, formRegistrationTitle } = Form2Style();
  const {
    primaryButton,
    backdrop,
  } = FormStyle();
  const nik = JSON.parse(localStorage.getItem("nik"));
  const kodeIuran = JSON.parse(localStorage.getItem("kodeIuran"));
  const navigate = useNavigate();

  const [loading] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });

  const handleCekTagihan = async () => {
    navigate(`/registration/success?nik=${nik}`)
  }

  const handlePopUpClose = () => {
    showErrorPopUp({ showPopUp: false });
  }

  return (
    <div id="SUCCESSPAGE" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* this div gets the gray backdrop effect on slide open */}
      <Loader loading={loading} />
      {/* this div gets the gray backdrop effect on slide open */}
      <div
        className={backdrop}
        style={{ display: loading || errorPopUp.showPopUp ? "unset" : "none" }}
      ></div>
      <PopupModal open={errorPopUp.showPopUp} message={"Something went wrong! Please try again."} error={true} handlePopUpClose={handlePopUpClose} />
      <Header text={"Registrasi Iuran BPU"} />
      <div className={formContainer} style={{ flex: "1", borderBottom: "1px solid #DDD" }}>
        <img src="images/bpjs-ketenagakerjaan.png" alt="logo" height="60" />
        <Typography className={formRegistrationTitle}>
          Formulir Registrasi
        </Typography>
        <div style={{ marginTop: '75px' }}>
          <Typography style={{ fontSize: "18px", color: "#808080" }}>
            Registrasi Anda telah berhasil
          </Typography>
          <div style={{ marginTop: '25px' }}>
            <Typography>
              Kode Iuran: <span style={{ fontWeight: 'bold' }}>{kodeIuran}</span>
            </Typography>
            <Typography>
              NIK: <span style={{ fontWeight: 'bold' }}>{nik}</span> <ContentCopyIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(nik) }} />
            </Typography>
          </div>
        </div>

      </div>


      <div>
        <Button id={"verifikasi-data"}
          className={primaryButton}
          onClick={handleCekTagihan}
        >
          CEK TAGIHAN
        </Button>
      </div>

    </div>
  );
};

export default SuccessPage;
