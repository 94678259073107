/* eslint-disable */
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { HeaderStyle } from '../Styles/index';
import { cancelEnrollment } from '../../api/ApiUtils';
import PopupModal from '../ErrorPage/PopupModal';
import { Loader } from '..';
import { FormStyle } from "../Styles";

const Header = ({ text, cancel = true }) => {

    const { headerContainer, headerTitle, headerBorder } = HeaderStyle();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });

    const {
        backdrop,
    } = FormStyle();

    const handleCancel = async () => {
        setLoading(true);
        const res = await cancelEnrollment();
        setLoading(false);
        if (res) {
            navigate("/cancelled")
        } else {
            showErrorPopUp({ showPopUp: true });
        }
    }

    const handlePopUpClose = () => {
        showErrorPopUp({ showPopUp: false });
    }

    if (errorPopUp.showPopUp) {
        return (
            <PopupModal open={errorPopUp.showPopUp} message={"Something went wrong! Please try again."} error={true} handlePopUpClose={handlePopUpClose} />
        )
    }


    return (
        <div id="header" style={{ background: "white" }}>
            <Loader loading={loading} />
            {/* this div gets the gray backdrop effect on slide open */}
            <div
                className={backdrop}
                style={{ display: loading || errorPopUp.showPopUp ? "unset" : "none" }}
            ></div>


            <div className={headerContainer}>
                <Typography className={headerTitle}>{text}</Typography>
                <IconButton style={{ display: cancel ? "" : "none" }} onClick={() => handleCancel()}><CloseIcon /></IconButton>
            </div>

            <div className={headerBorder}></div>

        </div>
    )
}

export default Header;