import { makeStyles } from "@mui/styles";

const Form1Style = makeStyles({
  formContainer: {
    padding: "16px",
    background: "white",
    marginTop: "8px",
  },

  submitButton: {
    width: "100% !important",
    background: "#4A90E2 !important",
    color: "white !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    padding: "15px !important",
    borderRadius: "0px !important",
  },

  submitButtonDisabled: {
    width: "100% !important",
    background: "#808080 !important",
    color: "white !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    padding: "15px !important",
    borderRadius: "0px !important",
  },
});

export default Form1Style;
