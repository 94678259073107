/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ValidateEnrollmentId } from "./api/ApiUtils";
import { Cancelled, Form1, Form2, InputBills, Loader, Redirect } from "./components";
import PopupModal from "./components/ErrorPage/PopupModal";
import SuccessPage from "./components/SuccessPage/SuccessPage";
import RedirectSuccess from "./components/Redirect/RedirectSuccess";

const App = () => {
  const [state, setstate] = useState({ isEnrollmentIdValid: false });
  const [loading, setLoading] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });

  useEffect(async () => {
    setLoading(true);

    try {
      let enrollmentId = window.location.search.split("=")[1];
      if (!enrollmentId) enrollmentId = JSON.parse(localStorage.getItem("enrollmentId") || "");

      if (enrollmentId != null) {
        localStorage.setItem("enrollmentId", JSON.stringify(enrollmentId));
        let response = await ValidateEnrollmentId()
        if (response.success) {
          setLoading(false)
          setstate({ isEnrollmentIdValid: true });
        }
        else {
          setstate({ isEnrollmentIdValid: false, errorMessage: response.message });
          showErrorPopUp({ showPopUp: true });
          setLoading(false)
        }
      }
    }
    catch (error) {
      setstate({ isEnrollmentIdValid: false, errorMessage: "Something went wrong! Please try again." });
      showErrorPopUp({ showPopUp: true });
      setLoading(false)
    }
  }, []);

  const handlePopUpClose = () => {
    showErrorPopUp({ showPopUp: false });
  }

  if (!state.isEnrollmentIdValid) {
    return (
      <div id="APP" style={{ background: "#f2f2f2" }}>
        <Loader loading={loading} />
        <PopupModal open={errorPopUp.showPopUp} message={state.errorMessage} error={true} handlePopUpClose={handlePopUpClose} />
      </div>
    );
  }
  return (
    <div id="APP" style={{ background: "#f2f2f2" }}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Form1 />} />
          <Route exact path="/formulir-registrasi" element={<Form2 />} />
          <Route exact path="/inputbills" element={<InputBills />} />
          <Route exact path="/redirect" element={<Redirect />} />
          <Route exact path="/cancelled" element={<Cancelled />} />
          <Route exact path="/success" element={<SuccessPage />} />
          <Route exact path="/registration/success" element={<RedirectSuccess />} />
          <Route exact path="/registration/failed" element={<RedirectSuccess />} />
          <Route exact path="/registration/already_registered" element={
            <div id="APP" style={{ background: "#f2f2f2" }}>
              <Loader loading={true} />
            </div>
          }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
