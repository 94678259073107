import { makeStyles } from '@mui/styles';

const FooterStyle = makeStyles({
    footerContainer: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "2px solid green",
        padding: "10px",
        background: "white"
    },
    footerText: {
        fontWeight: 'bold !important',
        fontSize: '12px !important',
        color: '#333',
        marginRight: "6px !important"
    }
})

export default FooterStyle;