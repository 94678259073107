/* eslint-disable */
import { Button, Paper, Slide, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Footer, Loader } from "..";
import { checkUser } from "../../api/ApiUtils";
import PopupModal from "../ErrorPage/PopupModal";
import { Form1Style, FormStyle } from "../Styles/index";
import { validateNik } from "../Validations/formValidations";

const Form1 = () => {
  const [state, setState] = useState({
    slideOpen: false,
    nik: "",
    fieldErrors: {
      nik: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const { formContainer, submitButton, submitButtonDisabled } = Form1Style();
  const {
    formFieldName,
    formInputFieldContainer,
    formInputField,
    primaryButton,
    secondaryButton,
    sliderContainer,
    backdrop,
  } = FormStyle();

  const navigate = useNavigate();

  const { slideOpen } = state;
  const nikMessage =
    "NIK yang anda masukkan sudah menjadi Peserta BPU BPJS Ketenagakerjaan, silahkan lanjutkan dengan pembayaran iuran atau cek NIK lain yang belum terdaftar.";

  const onChangeNikHandler = (e) => {
    let error = validateNik(e.target.value);
    setState({
      ...state,
      nik: e.target.value,
      fieldErrors: {
        ...state.fieldErrors,
        nik: error,
      },
    });
  };

  const onClickHandler = async () => {
    setLoading(true)
    const response = await checkUser(state.nik);
    if (!response.success) {
      localStorage.setItem("nik", JSON.stringify(state.nik));
      setLoading(false);
      navigate("/formulir-registrasi");
    } else if (response.responseCode !== '000') {
      setLoading(false);
      showErrorPopUp({ showPopUp: true });
      setState({ ...state, errorMessage: 'Something went wrong! Please try again.' })
    } else {
      setLoading(false);
      setState({ ...state, slideOpen: true });
    }
  };

  const handlePopUpClose = () => {
    showErrorPopUp({ showPopUp: false });
  }

  const handleOnClick = () => {
    navigate(`/registration/already_registered?nik=${state.nik}`);
  }

  return (
    <div id="FORM1" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* this div gets the gray backdrop effect on slide open */}
      <div
        className={backdrop}
        style={{ display: slideOpen ? "unset" : "none" }}
      ></div>

      <Loader loading={loading} />
      <Header text={"Pendaftaran Peserta Bukan Penerima Upah (BPU)"} />
      <PopupModal open={errorPopUp.showPopUp} message={state.errorMessage} error={true} handlePopUpClose={handlePopUpClose} />
      <div className={formContainer} style={{ flex: "1", borderBottom: "1px solid #DDD" }}>
        <Typography id={"nik-label"} className={formFieldName}>NIK</Typography>

        <TextField id={"nik-value"}
          variant={"standard"}
          type={"number"}
          placeholder="Masukkan NIK"
          className={formInputFieldContainer}
          inputProps={{ className: formInputField }}
          onChange={onChangeNikHandler}
          value={state.nik}
          error={state.fieldErrors.nik ? true : false}
          helperText={state.fieldErrors.nik}
        />

        <Button
          id={"cek-kepesertaan"}
          onClick={onClickHandler}
          className={
            state.fieldErrors.nik || !state.nik
              ? submitButtonDisabled
              : submitButton
          }
          disabled={state.fieldErrors.nik || !state.nik ? true : false}
        >
          CEK KEPESERTAAN
        </Button>
      </div>
      <Footer />

      <Slide
        in={slideOpen}
        direction="up"
        className={sliderContainer}
        style={{ display: slideOpen ? "inherit" : "none", flexDirection: "column" }}
      >
        <Paper style={{ textAlign: "center", padding: "24px 16px" }}>
          <Typography
            style={{ fontWeight: "bold", fontSize: "20px", color: "#333" }}
          >
            Nomor NIK sudah teregistrasi
          </Typography>
          <Typography style={{ margin: "8px 0 16px", fontSize: "14px" }}>
            {nikMessage}
          </Typography>
          <Button id={"bayar-iuran-lanjutan"} className={primaryButton} onClick={handleOnClick}>bayar iuran lanjutan</Button>

          <Button
            id={"cek-nik-lain"}
            className={secondaryButton}
            onClick={() => setState({ ...state, nik: "", slideOpen: false })}
          >
            CEK NIK LAIN
          </Button>
        </Paper>
      </Slide>
    </div>
  );
};

export default Form1;
