/* eslint-disable */
import React, { useState } from "react";
import { Header, Footer, Loader } from "..";
import { Button, Paper, Slide, TextField, Typography } from "@mui/material";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { id } from 'date-fns/locale'; // this is localization for Indonesian Language......
import { useNavigate } from "react-router-dom";

import VerifiedForm2 from "./VerifiedForm2";

import { Form2Style, FormStyle } from "../Styles/index";
import {
  validateAlamatEmail,
  validateAlamatPadaKTP,
  validateJamMulaiBekerja,
  validateJamSelesaiBekerja,
  validateJenisPekerjaan,
  validateKantorCabangBPJS,
  validateKtp,
  validateLokasiPekerjaanKota,
  validateLokasiPekerjaanProvinsi,
  validateNomorHP,
  validateTanggalLahirpadaKTP,
} from "../Validations/formValidations";
import moment from "moment";
import { getCityList, registerUser, verifyUser } from "../../api/ApiUtils";
import PopupModal from "../ErrorPage/PopupModal";
import SelectBranchDropDown from "./SelectBranchDropDown";

function Form2() {
  const { formContainer, formRegistrationTitle } = Form2Style();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openBranchDropDown, setOpenBranchDropDown] = useState(false);
  const [data, setData] = useState({
    branchList: [],
    jobList: [],
    provinceList: [],
    cityList: []
  });
  const {
    formFieldName,
    formInputFieldContainer,
    formInputField,
    primaryButton,
    secondaryButton,
    sliderContainer,
    backdrop,
    disabledButton,
    selectDateOfBirth
  } = FormStyle();

  const verificationMsg =
    "Nomor NIK tidak dapat diverifikasi, silakan hubungi BPJS Ketenagakerjaan";

  const [state, setState] = useState({
    slideOpen: false,
    verifyOpen: false,
    formBlock3: false,
    data: {
      tanggalLahirpadaKTP: null,
      kantorCabangBPJS: {},
      jenisPekerjaan: "",
      lokasiPekerjaanProvinsi: "",
      lokasiPekerjaanKota: "",
      jamMulaiBekerja: moment("0900", "hhmm").format(),
      jamSelesaiBekerja: moment("0700", "hhmm").format(),
      startTime: "09:00",
      endTime: "07:00",
      cityList: [],
      nik: JSON.parse(localStorage.getItem("nik")),
      ktp: '',
      normarHP: "",
    },
    fieldErrors: {},
  });
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const { slideOpen, verifyOpen, formBlock3 } = state;

  const onChangeKtpHandler = (e) => {
    let error = validateKtp(e.target.value);
    setState({
      ...state,
      data: { ...state.data, ktp: e.target.value },
      fieldErrors: {
        ...state.fieldErrors,
        ktp: error,
      },
    });
  };

  const onChangeTanggalLahirpadaKTP = (value) => {
    let error = validateTanggalLahirpadaKTP(value);

    setState({
      ...state,
      data: {
        ...state.data,
        tanggalLahirpadaKTP: value //!= null ? value.toISOString().split('T')[0] : null 
      },
      fieldErrors: {
        ...state.fieldErrors,
        tanggalLahirpadaKTP: error,
      },
    });
  };

  const onBlurTanggalKTPHandler = () => {
    let error = validateTanggalLahirpadaKTP(state.data.tanggalLahirpadaKTP);
    setState({
      ...state,
      fieldErrors: {
        ...state.fieldErrors,
        tanggalLahirpadaKTP: error,
      },
    });
  };

  const onChangeNomorHPHandler = (e) => {
    if (e.target.value.length <= 12) {
      let error = validateNomorHP(e.target.value);
      setState({
        ...state,
        data: { ...state.data, normarHP: e.target.value },
        fieldErrors: {
          ...state.fieldErrors,
          nomorHP: error,
        },
      });
    }
  };

  const onChangeEmailHandler = (e) => {
    let error = validateAlamatEmail(e.target.value);
    setState({
      ...state,
      data: { ...state.data, email: e.target.value },
      fieldErrors: {
        ...state.fieldErrors,
        email: error,
      },
    });
  };

  const onChangeAlamatPadaKTP = (e) => {
    let error = validateAlamatPadaKTP(e.target.value);
    setState({
      ...state,
      data: { ...state.data, alamatPadaKTP: e.target.value },
      fieldErrors: {
        ...state.fieldErrors,
        alamatPadaKTP: error,
      },
    });
  };

  const onChangeKantorCabangBPJS = (value) => {
    let error = validateKantorCabangBPJS(value);
    setState({
      ...state,
      data: { ...state.data, kantorCabangBPJS: value },
      fieldErrors: {
        ...state.fieldErrors,
        kantorCabangBPJS: error,
      },
      openBranch: false,
    });
  };
  const onChangeJenisPekerjaan = (e) => {
    let error = validateJenisPekerjaan(e.target.value);
    setState({
      ...state,
      data: { ...state.data, jenisPekerjaan: e.target.value },
      fieldErrors: {
        ...state.fieldErrors,
        jenisPekerjaan: error,
      },
    });
  };
  const onChangeLokasiPekerjaanProvinsi = async (e) => {
    let error = validateLokasiPekerjaanProvinsi(e.target.value);
    setLoading(true);
    let cityList = await getCityList(e.target.value);
    setLoading(false);
    setState({
      ...state,
      data: { ...state.data, lokasiPekerjaanProvinsi: e.target.value, cityList: cityList, lokasiPekerjaanKota: "" },
      fieldErrors: {
        ...state.fieldErrors,
        lokasiPekerjaanProvinsi: error,
      },
    });
  };
  const onChangeLokasiPekerjaanKota = (e) => {
    let error = validateLokasiPekerjaanKota(e.target.value);
    setState({
      ...state,
      data: { ...state.data, lokasiPekerjaanKota: e.target.value },
      fieldErrors: {
        ...state.fieldErrors,
        lokasiPekerjaanKota: error,
      },
    });
    localStorage.setItem("cityCode", JSON.stringify(e.target.value));
  };
  const onChangeJamMulaiBekerja = (value) => {
    let hours = value.getHours();
    let minutes = value.getMinutes();
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let startTime = hours + ":" + minutes;
    let error = validateJamMulaiBekerja(value);
    setState({
      ...state,
      data: { ...state.data, jamMulaiBekerja: value, startTime: startTime },
      fieldErrors: {
        ...state.fieldErrors,
        jamMulaiBekerja: error,
      },
    });
  };
  const onChangeJamSelesaiBekerja = (value) => {
    let hours = value.getHours();
    let minutes = value.getMinutes();
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let endTime = hours + ":" + minutes;
    let error = validateJamSelesaiBekerja(value);
    setState({
      ...state,
      data: { ...state.data, jamSelesaiBekerja: value, endTime: endTime },
      fieldErrors: {
        ...state.fieldErrors,
        jamSelesaiBekerja: error,
      },
    });
  };

  const onBlurKantorCabangBPJS = () => {
    let error = validateKantorCabangBPJS(state.data.kantorCabangBPJS.kode);
    setState({
      ...state,
      fieldErrors: {
        ...state.fieldErrors,
        kantorCabangBPJS: error,
      },
      openBranch: false
    });
  };
  const onBLurJenisPekerjaan = () => {
    let error = validateJenisPekerjaan(state.data.jenisPekerjaan);
    setState({
      ...state,
      fieldErrors: {
        ...state.fieldErrors,
        jenisPekerjaan: error,
      },
    });
  };
  const onBlurLokasiPekerjaanProvinsi = () => {
    let error = validateLokasiPekerjaanProvinsi(
      state.data.lokasiPekerjaanProvinsi
    );
    setState({
      ...state,
      fieldErrors: {
        ...state.fieldErrors,
        lokasiPekerjaanProvinsi: error,
      },
    });
  };
  const onBlurLokasiPekerjaanKota = () => {
    let error = validateLokasiPekerjaanKota(state.data.lokasiPekerjaanKota);
    setState({
      ...state,
      fieldErrors: {
        ...state.fieldErrors,
        lokasiPekerjaanKota: error,
      },
    });
  };

  const hasFormError = () => {
    return state.fieldErrors.nik ||
      state.fieldErrors.ktp ||
      state.fieldErrors.tanggalLahirpadaKTP ||
      state.fieldErrors.nomorHP ||
      !state.data.nik ||
      !(state.data.ktp.length >= 1 && state.data.ktp.length <= 30) ||
      !state.data.tanggalLahirpadaKTP ||
      !(state.data.normarHP.length >= 8 && state.data.normarHP.length <= 12)
      ? true
      : false;
  };

  const hasForm2Error = () => {
    return state.fieldErrors.kantorCabangBPJS ||
      state.fieldErrors.jenisPekerjaan ||
      state.fieldErrors.lokasiPekerjaanProvinsi ||
      state.fieldErrors.lokasiPekerjaanKota ||
      state.fieldErrors.alamatPadaKTP ||
      state.fieldErrors.email ||
      !state.data.alamatPadaKTP ||
      !state.data.email ||
      !state.data.kantorCabangBPJS.kode ||
      !state.data.jenisPekerjaan ||
      !state.data.lokasiPekerjaanProvinsi ||
      !state.data.lokasiPekerjaanKota
      ? true
      : false;
  };

  const handleVerifyUser = async () => {
    let date = state.data.tanggalLahirpadaKTP //.split("-")
    const requestBody = {
      "nik": state.data.nik,
      "noHp": state.data.normarHP,
      "namaKtp": state.data.ktp,
      "tglLahir": moment(date).format("DD-MM-YYYY")//`${date[2]}-${date[1]}-${date[0]}`,
    }
    setLoading(true);
    const response = await verifyUser(requestBody);
    if (response.success) {
      setLoading(false);
      setState({ ...state, verifyOpen: true })
    } else if (response.responseCode !== '199') {
      setLoading(false);
      showErrorPopUp({ showPopUp: true });
      setState({ ...state, errorMessage: 'Something went wrong! Please try again.' })
    } else {
      setLoading(false);
      setState({ ...state, slideOpen: true })
    }
  }

  const handleOnClickSubmit = async () => {
    let date = state.data.tanggalLahirpadaKTP //.split("-");
    const requestBody = {
      "nik": state.data.nik,
      "noHp": state.data.normarHP,
      "email": state.data.email,
      "nama": state.data.ktp,
      "tglLahir": moment(date).format("DD-MM-YYYY"),
      // `${date[2]}-${date[1]}-${date[0]}`,
      "alamat": state.data.alamatPadaKTP,
      "kodeKantor": state.data.kantorCabangBPJS.kode,
      "jenisPekerjaan": state.data.jenisPekerjaan,
      "lokasiPekerjaan": state.data.lokasiPekerjaanKota,
      "jamAwal": state.data.startTime,
      "jamAkhir": state.data.endTime,
    }
    setLoading(true);
    const response = await registerUser(requestBody);
    if (response) {
      setLoading(false);
      navigate("/inputbills");
    } else {
      setLoading(false);
      showErrorPopUp({ showPopUp: true });
      setState({ ...state, errorMessage: 'Something went wrong! Please try again.' })
    }
  }

  const handlePopUpClose = () => {
    showErrorPopUp({ showPopUp: false });
  }

  const handleOnOpen = () => { setState({ ...state, openBranch: true }) }

  const handleOnClose = () => {
    setState({ ...state, openBranch: false })
  }

  return (
    <div id="FORM2">
      <Loader loading={loading} />
      {/* this div gets the gray backdrop effect on slide open */}
      <div
        className={backdrop}
        style={{ display: slideOpen || errorPopUp.showPopUp ? "unset" : "none" }}
      ></div>
      <PopupModal open={errorPopUp.showPopUp} message={state.errorMessage} error={true} handlePopUpClose={handlePopUpClose} />
      <Header
        text={
          verifyOpen || formBlock3
            ? "Registrasi Iuran BPU"
            : "Pendaftaran Peserta Bukan Penerima Upah (BPU)"
        }
      />

      <div className={formContainer}>
        <img src="images/bpjs-ketenagakerjaan.png" alt="logo" height="60" />

        <Typography className={formRegistrationTitle}>
          Formulir Registrasi
        </Typography>
        <Typography style={{ fontSize: "14px", color: "#666" }}>
          Isi data berikut untuk memulai registrasi BPJS Bukan Penerima Upah
        </Typography>

        {!formBlock3 ? (
          <div style={{ marginTop: "24px", textAlign: "left" }}>
            <Typography id={"nik2-label"} className={formFieldName}>NIK</Typography>
            <TextField id={"nik2-value"}
              variant={"standard"}
              placeholder="Masukkan NIK"
              className={formInputFieldContainer}
              inputProps={{ className: formInputField }}
              value={state.data.nik}
              disabled={true}
            />

            <Typography id={"nama-pada-ktp-label"} className={formFieldName}>Nama pada KTP</Typography>
            <TextField id={"nama-pada-value"}
              variant={"standard"}
              placeholder="Nama pada KTP"
              className={formInputFieldContainer}
              inputProps={{ className: formInputField }}
              onChange={onChangeKtpHandler}
              error={state.fieldErrors.ktp ? true : false}
              helperText={state.fieldErrors.ktp}
              value={state.data.ktp}
              disabled={verifyOpen}
            />

            <Typography id={"tanggal-lahir-pada-ktp-label"} className={formFieldName}>
              Tanggal Lahir pada KTP
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={id} >
              <MobileDatePicker
                disableFuture
                cancelText=""
                DialogProps={{ className: selectDateOfBirth }}
                onChange={onChangeTanggalLahirpadaKTP}
                value={state.data.tanggalLahirpadaKTP}
                disabled={verifyOpen}
                className={formInputFieldContainer}
                renderInput={(params) => {
                  if (params.inputProps.value !== "")
                    params.inputProps.value = moment(params.inputProps.value, "DD/MM/YYYY").locale("id").format("DD-MM-YYYY")
                  return (
                    <TextField id={"tanggal-lahir-pada-ktp-value"}
                      variant={"standard"}
                      onBlur={onBlurTanggalKTPHandler}
                      placeholder="Tanggal Lahir pada KTP"
                      className={formInputFieldContainer}
                      inputProps={{ className: formInputField }}
                      {...params}
                      error={state.fieldErrors.tanggalLahirpadaKTP ? true : false}
                      helperText={state.fieldErrors.tanggalLahirpadaKTP}
                    />
                  )
                }}
              />
            </LocalizationProvider>

            <Typography id={"nomor-hp-label"} className={formFieldName}>Nomor HP</Typography>
            <TextField id={"nomor-hp-value"}
              variant={"standard"}
              placeholder="Nomor HP harus dimulai dari 0"
              type={"number"}
              className={formInputFieldContainer}
              inputProps={{ className: formInputField }}
              onChange={onChangeNomorHPHandler}
              error={state.fieldErrors.nomorHP ? true : false}
              helperText={state.fieldErrors.nomorHP}
              value={state.data.normarHP}
              disabled={verifyOpen}
            />

            <VerifiedForm2
              onChangeEmailHandler={onChangeEmailHandler}
              onChangeAlamatPadaKTP={onChangeAlamatPadaKTP}
              onChangeKantorCabangBPJS={onChangeKantorCabangBPJS}
              onChangeJenisPekerjaan={onChangeJenisPekerjaan}
              onChangeLokasiPekerjaanProvinsi={onChangeLokasiPekerjaanProvinsi}
              onChangeLokasiPekerjaanKota={onChangeLokasiPekerjaanKota}
              onChangeJamMulaiBekerja={onChangeJamMulaiBekerja}
              onChangeJamSelesaiBekerja={onChangeJamSelesaiBekerja}
              onBlurKantorCabangBPJS={onBlurKantorCabangBPJS}
              onBLurJenisPekerjaan={onBLurJenisPekerjaan}
              onBlurLokasiPekerjaanProvinsi={onBlurLokasiPekerjaanProvinsi}
              onBlurLokasiPekerjaanKota={onBlurLokasiPekerjaanKota}
              state={state}
              verifyOpen={verifyOpen}
              FormStyle={FormStyle}
              handleOnOpen={handleOnOpen}
              handleOnClose={handleOnClose}
              setOpenBranchDropDown={setOpenBranchDropDown}
              setData={setData}
              data={data}
            />
          </div>
        ) : ''}
      </div>

      {
        !verifyOpen ?

          <div style={{ padding: "16px" }}>
            <Button id={"verifikasi-data"}
              className={hasFormError() ? disabledButton : primaryButton}
              onClick={handleVerifyUser}
              disabled={hasFormError() ? true : false}
            >
              VERIFIKASI DATA
            </Button>
          </div>
          :
          <div style={{ padding: "16px" }}>
            <Button id={"lanjutkan"}
              className={hasForm2Error() ? disabledButton : primaryButton}
              onClick={handleOnClickSubmit}
              disabled={hasForm2Error() ? true : false}
            >
              LANJUTKAN
            </Button>
          </div>

      }

      <Footer />

      <Slide
        in={slideOpen}
        direction="up"
        className={sliderContainer}
        style={{ display: slideOpen ? "inherit" : "none" }}
      >
        <Paper style={{ textAlign: "center", padding: "24px 16px" }}>
          <Typography style={{
            margin: "8px 0 16px", fontSize: '20px',
            fontWeight: 'bold'
          }}>
            {verificationMsg}
          </Typography>

          <Button id={"tutup"}
            className={secondaryButton}
            onClick={() => {
              setState({ ...state, slideOpen: false });
              navigate("/");
            }
            }
          >
            TUTUP
          </Button>
        </Paper>
      </Slide>
      {openBranchDropDown && <SelectBranchDropDown
        list={data.branchList || []}
        slideOpen={openBranchDropDown}
        setOpenBranchDropDown={setOpenBranchDropDown}
        onChangeKantorCabangBPJS={onChangeKantorCabangBPJS}
        title={"Kantor Cabang BPJS"}
      />}
    </div>
  );
}

export default Form2;
