import { makeStyles } from '@mui/styles';

const HeaderStyle = makeStyles({
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px"
    },
    headerTitle: {
        fontWeight: '500 !important',
        fontSize: '20px !important',
        color: '#333'
    },
    headerBorder: {
        width: "100%",
        height: '3px',
        background: '#4A90E2'
    }
})

export default HeaderStyle;