import React from 'react';

import { Header, Footer } from "../index";
import { Typography, LinearProgress } from "@mui/material";
import { Form2Style } from "../Styles/index";

const Cancelled = () => {
    const { formRegistrationTitle } = Form2Style();
    return (
        <div style={{ background: "#fff", height: "100vh", display: "flex", flexDirection: "column" }}>
            <Header text={"Ringkasan"} cancel={false} />
            <div style={{ textAlign: "center", padding: "16px", flex: "1", borderBottom: "1px solid #DDD" }}>
                <img src='/images/bpjs-ketenagakerjaan.png' height={80} alt="logo" />

                <Typography className={formRegistrationTitle} >Formulir Registrasi</Typography>
                <Typography style={{ fontSize: "14px", color: "#666", marginTop: "24px", marginBottom: "24px" }}>
                    Anda sedang diarahkan ke halaman lain. Proses ini memakan waktu 10 detik
                </Typography>

                <LinearProgress style={{ height: "12px", borderRadius: "2px" }} />
                <Typography children={"Mohon menunggu..."} style={{ fontStyle: "italic", marginTop: "8px", color: "#666" }} />
            </div>
            <Footer />
        </div>
    )
}

export default Cancelled