import React from 'react'
import { Typography } from '@mui/material';
import { FooterStyle } from '../Styles/index';
import ayoConnectLogo from '../../assets/images/ayoconnect.png';

const Footer = () => {
    const { footerContainer, footerText } = FooterStyle();

    return (
        <div className={footerContainer}>
            <Typography className={footerText}>Didukung oleh:</Typography>
            <img src={ayoConnectLogo} alt="logo" height="24" />
        </div>
    )
}

export default Footer;