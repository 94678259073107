import React from 'react';
import { InputAdornment, Stack, TextField } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

function Clock({ defaultTime, setTime, FormStyle, id }) {

    const { formInputFieldContainer, formInputField, clock } = FormStyle();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <MobileTimePicker className={clock}
                    value={defaultTime}
                    onChange={newValue => setTime(newValue)}

                    renderInput={params => {
                        return <TextField id={id}
                            className={formInputFieldContainer} inputProps={{ className: formInputField }}
                            variant={"standard"}
                            {...params}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccessTimeIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                    }
                />
            </Stack>
        </LocalizationProvider>
    );
}

export default Clock;